import * as Sentry from '@sentry/svelte';
import { PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';

Sentry.init({
	dsn: 'https://1f32d5498b28de112e6cf3be7cdfb4ab@o4505999977938944.ingest.sentry.io/4506662213713920',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 0.1,

	environment: PUBLIC_SENTRY_ENVIRONMENT || 'unknown',

	integrations: [
		new Sentry.Replay(),
		new Sentry.BrowserTracing(),
		new Sentry.BrowserProfilingIntegration()
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});
