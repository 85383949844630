import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0,2,4,3];

export const dictionary = {
		"/": [5],
		"/email-verification/[email]": [~10],
		"/error": [11],
		"/(auth)/login": [~6,[2]],
		"/onboarding": [~12,[4]],
		"/profile": [~13],
		"/(auth)/signup": [~7,[2]],
		"/[username]": [~8,[3]],
		"/[username]/referral": [~9,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';